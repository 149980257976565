import DensityLargeOutlinedIcon from "@mui/icons-material/DensityLargeOutlined";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import { Badge, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { RenderWithScope } from "authorization";
import UploadCertificateButton from "components/molecules/UploadCertificateButton";
import {
  filterValueLengthAtom,
  openTaskListLengthAtom,
} from "pages/CertificateValidation/OpenTaskList/CustomOpenTaskFilters/State/manageFiltersInOpenTaskList";
import {
  ButtonToolbarSortSC,
  StyledTopbarStartContainer,
  StyledTypographyVerticalTabs,
} from "pages/CertificateValidation/styles";
import CustomDataGridFilters from "pages/CertificationManagement/CustomDataGridFilters";
import UnitsQuickFilter from "pages/CertificationManagement/CustomDataGridFilters/UnitsQuickFilter";
import { tenantHasRoleManufacturerOrCertifcationAuthorityAtom } from "pages/CertificationManagement/UnitsOverview";
import { MouseEvent, useMemo } from "react";
import { useRecoilValue } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import {
  StyledGridToolbarQuickFilter,
  StyledToolbarBox,
  StyledToolbarContainerEnd,
  StyledToolbarContainerStart,
} from "../../../dataGridTable/styles";
import MigrationNote from "../../MigrationNote";
import { globalShowMigrationNoteState, globalUnitNotFoundMessageAtom } from "../../MigrationNote/recoilState";
import { StyledMigrationNoteContainer } from "../../MigrationNote/styles";

export const filterButtonStyles = (hasFilters?: boolean) => {
  return {
    "& .MuiBadge-badge": {
      display: "flex",
      width: "30px",
      height: "30px",
      padding: "0px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "36px",
      background: "#000",
      color: ordinaryColors.unnamed4,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "143% /* 25.74px */",
      letterSpacing: "0.17px",
      margin: "13px 4px 0px 0px",
    },
    "& .MuiSvgIcon-root": {
      marginRight: hasFilters ? "30px" : "0px",
    },
    height: "43px",
    padding: "0px 16px",
    gap: hasFilters ? "12px" : "8px",
    borderRadius: "0px",
    border: `1px solid ${ordinaryColors.functionalLightGrey}`,
  };
};

interface ToolbarItem {
  id: string;
  element: React.ReactElement;
}
type RenderElements = (defaultElements: ToolbarItem[]) => React.ReactElement;

export interface CustomToolbarProps {
  refetchOnClick?: () => void;
  renderElementsStart?: RenderElements;
  renderElementsEnd?: RenderElements;
  deps?: React.DependencyList;
  hasFilters?: boolean;
  isListToolbar?: boolean;
  isUnitsToolbar?: boolean;
  hasCustomFilterbar?: boolean;
  unitsToolbarBackgroundColor?: string;
  showMigrationNote?: boolean;
  showReducedFunctionalityNote?: boolean;

  handleFilterAnchor?: (event: MouseEvent<HTMLButtonElement>) => void;
  handleSortAnchor?: (event: MouseEvent<HTMLButtonElement>) => void;
}
const defaultElementsArrayStart = [
  {
    id: "GridToolbarFilterButton",
    element: (
      <GridToolbarFilterButton
        sx={{
          minWidth: "0px",
        }}
        componentsProps={{ button: { startIcon: <FilterListOutlinedIcon sx={{ fontSize: "44px" }} /> } }}
      />
    ),
  },
  {
    id: "GridToolbarDensitySelector",
    element: <GridToolbarDensitySelector startIcon={<DensityLargeOutlinedIcon />} />,
  },
  {
    id: "GridToolbarColumnsButton",
    element: <GridToolbarColumnsButton startIcon={<SettingsOutlinedIcon />} />,
  },
];
const getDefaultElementsArrayEnd = () => [
  {
    id: "GridToolbarSearchInput",
    element: (
      <StyledGridToolbarQuickFilter
        sx={{ ".MuiOutlinedInput-root": { backgroundColor: ordinaryColors.functionalWhite } }}
        quickFilterParser={handleSearch}
        variant="outlined"
        placeholder={translations.globals.table.searchTableDefault}
      />
    ),
  },
];

const renderDefaultElements: RenderElements = (elements) => (
  <>
    {elements.map(({ element, id }) => (
      <div key={id}>{element}</div>
    ))}
  </>
);
function handleSearch(v: string) {
  return [v];
}
/**
 * @returns an Array of Objects with default Elements for the toolbar. It is devided into two slots
 * and accepts two Arguments.
 *
 * Parameter renderElementsStart: displays the rendered Items from the Array defaultElementsArrayStart.
 *
 * Parameter renderElemntsEnd: displays the rendered Items from the Array defaultElementsArrayEnd.
 *
 * Additional Components can be added as needed.
 *
 */

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  hasFilters,
  isUnitsToolbar,
  isListToolbar,
  hasCustomFilterbar,
  renderElementsStart,
  renderElementsEnd,
  showMigrationNote,
  handleFilterAnchor,
  handleSortAnchor,
}) => {
  const openTaskListLength = useRecoilValue(openTaskListLengthAtom);
  const filterValueLength = useRecoilValue(filterValueLengthAtom);
  const showReducedFunctionalityNote = useRecoilValue(tenantHasRoleManufacturerOrCertifcationAuthorityAtom);
  const renderElementsEndInner = renderElementsEnd ?? renderDefaultElements;
  const open = useRecoilValue(globalShowMigrationNoteState);
  const openUnitNotFoundMessage = useRecoilValue(globalUnitNotFoundMessageAtom);
  const defaultElements = useMemo(
    () => (
      <>
        <StyledToolbarContainerStart>{renderDefaultElements(defaultElementsArrayStart)}</StyledToolbarContainerStart>

        <StyledToolbarContainerEnd>{renderElementsEndInner(getDefaultElementsArrayEnd())}</StyledToolbarContainerEnd>
      </>
    ),
    [renderElementsEndInner]
  );
  return (
    <>
      {isListToolbar ? (
        <>
          <StyledTopbarStartContainer>
            <StyledTypographyVerticalTabs>
              {`${translations.pages.certificateValidation.label} (${openTaskListLength ?? "0"})`}
            </StyledTypographyVerticalTabs>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <ButtonToolbarSortSC
                startIcon={
                  filterValueLength && filterValueLength > 0 ? (
                    <Badge badgeContent={filterValueLength} color="primary">
                      <FilterAltOutlined htmlColor={ordinaryColors.functionalBlack} />
                    </Badge>
                  ) : (
                    <FilterAltOutlined htmlColor={ordinaryColors.functionalBlack} />
                  )
                }
                onClick={handleFilterAnchor}
              />

              <ButtonToolbarSortSC
                // sx={(theme) => toolbarColor(theme)}
                startIcon={<SortOutlinedIcon htmlColor={ordinaryColors.functionalBlack} />}
                onClick={handleSortAnchor}
              ></ButtonToolbarSortSC>
            </Box>
          </StyledTopbarStartContainer>
          <StyledGridToolbarQuickFilter
            sx={{ ".MuiOutlinedInput-root": { backgroundColor: ordinaryColors.grey200 } }}
            quickFilterParser={handleSearch}
            variant="outlined"
            placeholder={translations.globals.table.searchTasks}
          />
        </>
      ) : null}
      {isUnitsToolbar ? (
        <>
          <StyledToolbarBox
            props={{
              unitsToolbarBackgroundColor: ordinaryColors.functionalLightGreyBack,
            }}
          >
            <StyledToolbarContainerStart>
              <UnitsQuickFilter />
              <GridToolbarFilterButton sx={() => filterButtonStyles(hasFilters)} />
            </StyledToolbarContainerStart>
            <StyledToolbarContainerEnd>
              <RenderWithScope scope="Certificate.Create">
                <UploadCertificateButton />
              </RenderWithScope>
            </StyledToolbarContainerEnd>
          </StyledToolbarBox>
        </>
      ) : renderElementsStart ? (
        <StyledToolbarContainerStart>
          <GridToolbarFilterButton
            sx={{
              minWidth: "0px",
            }}
            componentsProps={{ button: { startIcon: <FilterListOutlinedIcon sx={{ fontSize: "44px" }} /> } }}
          />
        </StyledToolbarContainerStart>
      ) : (
        !isListToolbar && defaultElements
      )}
      {hasCustomFilterbar ? (
        <>
          <>
            <Divider />
            <CustomDataGridFilters />
          </>
          <StyledMigrationNoteContainer gap="24px">
            {showReducedFunctionalityNote && (
              <MigrationNote
                note={translations.globals.notifications.reducedFunctionalityNote}
                canClose={false}
                backgroundColor={ordinaryColors.primaryBrand50}
              />
            )}
            {open && showMigrationNote && (
              <MigrationNote note={translations.globals.notifications.migrationNote} showIcon />
            )}
            {openUnitNotFoundMessage && (
              <MigrationNote
                note={translations.globals.notifications.unitOrCertificateNotFoundMessage}
                backgroundColor={ordinaryColors.reducedFunctionalityNoteColor}
                isUnitNotFoundMessage
              />
            )}
          </StyledMigrationNoteContainer>
        </>
      ) : null}
    </>
  );
};

export default CustomToolbar;
