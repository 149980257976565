const singleMultiNameMappings: { [key: string]: string } = {
  accessRequest: "accesRequests",
  certificateBridgeCertificateNormIssueDate: "certificateBridgeCertificateNormIssueDates",
  certificateBridgeSoftwareVersion: "certificateBridgeSoftwareVersions",
  certificateBridgeUnit: "certificateBridgeUnits",
  certificate: "certificates",
  certificateFile: "certificateFiles",
  certificateFileType: "certificateFileTypes",
  certificateNormBridge: "certificateNormBridges",
  certificateNorm: "certificateNorms",
  certificateNormIssueDate: "certificateNormIssueDates",
  certificateType: "certificateTypes",
  certificateValidityStatus: "certificateValidityStatuses",
  customCertificate: "customCertificateCreate",
  customCertificateFile: "certificateFileCreates",
  issue: "issues",
  issueHistoryBridgeLedger: "issueHistoryBridgeLedgers",
  issueHistory: "issueHistories",
  issueStatus: "issueStatuses",
  issueSubType: "issueSubTypes",
  issueType: "issueTypes",
  notification: "notifications",
  notificationEvent: "notificationEvents",
  notificationMethod: "notificationMethods",
  notificationRule: "notificationRules",
  roleBridgeScope: "roleBridgeScopes",
  role: "roles",
  softwareVersion: "softwareVersions",
  subscription: "subscriptions",
  tenant: "tenants",
  topic: "topics",
  unitBridgeTenant: "unitBridgeTenants",
  unitBridgeUser: "unitBridgeUsers",
  unit: "unitCreates",
  unitType: "unitTypes",
  userBridgeRole: "userBridgeRoles",
  userBridgeTenant: "userBridgeTenants",
  user: "users",
};
export default singleMultiNameMappings;

export const multiSingleNameMappings: { [key: string]: string } = {
  accesRequests: "accessRequest",
  certificateBridgeCertificateNormIssueDates: "certificateBridgeCertificateNormIssueDate",
  certificateBridgeSoftwareVersions: "certificateBridgeSoftwareVersion",
  certificateBridgeUnits: "certificateBridgeUnit",
  certificates: "certificate",
  certificateFiles: "certificateFile",
  certificateFileTypes: "certificateFileType",
  certificateNormBridges: "certificateNormBridge",
  certificateNorms: "certificateNorm",
  certificateNormIssueDates: "certificateNormIssueDate",
  certificateTypes: "certificateType",
  certificateValidityStatuses: "certificateValidityStatus",
  customCertificateCreate: "customCertificate",
  certificateFileCreates: "customCertificateFile",
  issues: "issue",
  issueHistoryBridgeLedgers: "issueHistoryBridgeLedger",
  issueHistories: "issueHistory",
  issueStatuses: "issueStatus",
  issueSubTypes: "issueSubType",
  issueTypes: "issueType",
  notifications: "notification",
  notificationEvents: "notificationEvent",
  notificationMethods: "notificationMethod",
  notificationRules: "notificationRule",
  roleBridgeScopes: "roleBridgeScope",
  roles: "role",
  softwareVersions: "softwareVersion",
  subscriptions: "subscription",
  tenants: "tenant",
  topics: "topic",
  unitBridgeTenants: "unitBridgeTenant",
  unitBridgeUsers: "unitBridgeUser",
  unitCreates: "unit",
  unitTypes: "unitType",
  userBridgeRoles: "userBridgeRole",
  userBridgeTenants: "userBridgeTenant",
  users: "user",
};
