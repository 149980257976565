export default {
  assignRolesToUser: {
    label: "Rollen zu Benutzer Zuweisung",
  },
  button: {
    create: {
      role: "neue Rolle",
      apiKey: "Neuer API-Schlüssel",
    },
  },
  form: {
    //   edit: {
    //     listLabel: {
    //       assigned: "Zugewiesen an",
    //       unassigned: "Nicht zugeordnet",
    //     },
    //     tabLabel: {
    //       assignScope: "Berechtigungen zuordnen",
    //       assignUser: "Benutzer zuordnen",
    //       editRole: "Rolle ändern",
    //     },
    //   },
    label: {
      revokeApiKey: "API-Schlüssel widerrufen",
      deleteUserRole: "Benutzerrolle löschen",
      editRole: "Rolle bearbeiten",
      role: "Rolle bearbeiten",
      allUsers: "Alle Benutzer",
      keyLabel: "Schlüsselbezeichnung",
      tenantDeletionFailed: "Organisationlöschung fehlgeschlagen",
      tenantDeleteSuccess: "Organisation erfolgreich gelöscht",
      deleteTenant: "Organisation löschen",
      checkTenantRole: "Organisationsrolle prüfen",
      // user: "Benutzer bearbeiten",
    },
    error: {
      disabledButtonForCreate: "Für diese Aktion fehlen die erforderlichen Berechtigungen.",
      maxApiKey: "Es können maximal zwei API-Schlüssel gleichzeitig verwaltet werden.",
      deleteUserRole:
        "Benutzerrolle kann nicht gelöscht werden, Organisation sollte mindestens einen Administrator haben.",
    },
    message: {
      continueDeleteMessage: "Sind Sie sicher, dass Sie weitermachen wollen?",
      userRoleDeleteMessagePt1: "Diese Benutzerrolle ist derzeit zugewiesen an",
      userRoleDeleteMessagePt2: "Sie werden keinen Zugang mehr zu bestimmten App-Bereichen haben.",
      userErrorMessage: "der Benutzer darf nicht leer sein.",
      userNameErrorMessage: "Der Benutzername darf nicht leer sein.",
      userLastNameErrorMessage: "Der Benutzer Nachname darf nicht leer sein.",
      phoneNumerErrorMessage: "Der eingegebene Wert muss eine Telefonnummer sein.",
      streetAndNumberErrorMessage:
        "Bitte geben Sie den Straßennamen gefolgt von einem Komma und der Hausnummer ein (z.B. Hauptstraße, 123).",
      postalCodeErrorMessage:
        "Bitte geben Sie eine gültige Postleitzahl ein, die nur aus einer oder mehreren Ziffern besteht.",
      requiredInformation: "Diese Information ist erforderlich.",
      tenantNameExists: "Der Name der Organisation existiert bereits, bitte geben Sie einen anderen Namen ein.",
      userBlockMessage: "Sind Sie sich sicher dass Sie diesen Benutzer blockieren möchten?",
      userUnblockMessage: "Sind Sie sich sicher dass Sie diesen Benutzer entsperren möchten?",
      apiDescription: "Um mehr darüber zu erfahren, wie API-Schlüssel funktionieren, lesen Sie bitte die ",
      externalDocumentation: "externe Dokumentation",
      revokeApiKey: "Sind Sie sicher, dass Sie diesen geheimen Schlüssel widerrufen möchten?",
      newApiKeyMessagePart1: " Hier ist Ihr API-Schlüssel für den ",
      newApiKeyMessagePart2: " Test.",
      newApiKeyMessagePart3:
        " Bitte beachten Sie, dass Sie diesen Schlüssel nach dem Schließen dieses Fensters nicht mehr sehen können, notieren Sie ihn also gut.",
      newApiKeyWarningMessage:
        "Es scheint, dass beim Erstellen des API-Schlüssels ein Fehler aufgetreten ist. Bitte versuchen Sie es erneut und wenden sich an den Plattformbetreiber, falls das Problem weiterhin bestehen sollte.",
      deleteTenantNotPossible: "Organisation darf nicht gelöscht werden, da noch Benutzer zugewiesen sind",
      deleteTenantSuccess: "Die alte Organisation wurde erfolgreich gelöscht.",
      tenantCanBeDeleted: ({ tenantName }: { tenantName: string | undefined }) =>
        `Der Organisation ${
          tenantName && `"${tenantName}" `
        }sind keine Benutzer mehr zugeordnet. Möchten Sie diese Organisation aus der Datenbank entfernen?`,
      tenantCanNotBeDeleted: ({ tenantName }: { tenantName: string | undefined }) =>
        `Die Organisation ${
          tenantName && `"${tenantName}" `
        }kann derzeit nicht gelöscht werden, da dieser Organisation momentan noch weitere Benutzer zugeordnet sind. Weisen sie zuvor alle verbliebenen Benutzer einer neuen Organisation zu, um ${
          tenantName ? `"${tenantName}"` : "sie"
        } aus der Datenbank entfernen zu können.`,
      checkTenantRole: ({ tenantName }: { tenantName: string | undefined }) =>
        `Die aktuelle Rolle des Benutzers stimmt nicht mit der Rolle der neu zugewiesenen Organisation ${
          tenantName && `"${tenantName}" `
        } überein. Möchten Sie trotzdem fortfahren?`,
      retryInviteUser: ({ displayName }: { displayName: string }) =>
        `Möchten Sie wirklich ${displayName} erneut einladen?`,
    },
  },
  inviteUsers: {
    label: "Benutzer einladen",
    formFields: {
      firstName: "Vorname",
      lastName: "Nachname",
      emailAdress: "Email-Adresse",
    },
  },
  label: "Benutzerverwaltung",
  table: {
    column: {
      label: "Label",
      secret: "Geheimnis",
      description: "Beschreibung",
      email: "Email",
      firstName: "Vorname",
      lastName: "Nachname",
      name: "Name",
      clientId: "Client ID",
      pendingRequestsAction: "Aktionen",
      explanationWhyRevokeButtonIsDisabled:
        "Aus technischen Gründen muss ein Schlüssel vorhanden sein. Falls Sie diesen Schlüssel widerrufen möchten, erstellen sie bitte vorab einen neuen API-Schlüssel.",
      role: "Rollen des Benutzers",
      roleDescription: "Beschreibung der Rolle",
      tenant: "Mieter / Unternehmen",
      userRole: "Name der Benutzerrolle",
      value: "Wert",
    },
  },
  tabs: {
    pendingUserRequests: "Ausstehende Benutzeranfragen",
    userAndRolesAssignment: "Benutzer- und Rollenzuweisung",
    apiKeys: "API-Schlüssel",
  },

  userAuthorization: "User Authorization",
  tenantOverview: "Organisationen Übersicht",
  userRoleManager: {
    assignedTo: "Assigned To",
    createdUserRole: "Ihre Benutzerrolle wurde erfolgreich erstellt.",
    discardChanges: "Änderungen verwerfen",
    discardMessage:
      "Sie haben Änderungen vorgenommen, die nicht gespeichert sind. Sind Sie sicher, dass Sie diese Seite verlassen und die Änderungen ungespeichert lassen wollen?",
    inviteUser: "Benutzer einladen",
    label: "User Role Manager",
    noUsersAssignedMessage: "Es gibt noch keine Benutzer, die dieser Rolle zugeordnet sind.",
    permissions: "Permissions",
    roleName: "Rollenname",
    selectRole: "Select Role",
    useRoleCreate: "Benutzerrolle erstellt",
  },
};
