import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUnitsForUnitsOverviewQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.UnitOverviewFilterInput>;
  order?: Types.InputMaybe<Array<Types.UnitOverviewSortInput> | Types.UnitOverviewSortInput>;
  isLoggedIn?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetUnitsForUnitsOverviewQuery = { __typename?: 'Query', unitOverview?: { __typename: 'UnitOverviewCollectionSegment', totalCount: number, items?: Array<{ __typename: 'UnitOverview', id: string, isImported: boolean, tenantId: string, modelName: string, manufacturerId: string, ratedCurrent?: number | null, unitCode: string, maxActivePower?: number | null, hasActivePowerRange?: boolean | null, hasApparentPowerRange?: boolean | null, certificateId: string, maxActivePowerRange?: number | null, maxApparentPowerRange?: number | null, minActivePowerRange?: number | null, minApparentPowerRange?: number | null, ratedVoltage?: number | null, unitTypeId: string, certificateNormIssueDateDescriptions?: string | null, is4105: boolean, category: Types.Category, certificateIsImported: boolean, isVerified?: boolean | null, certificateIssueDate?: string | null, certificateHolderId: string, primaryEnergySource: Types.PrimaryEnergySource, certificateNumber: string, validityStartDate?: string | null, validityEndDate?: string | null, inEditByTenantId?: string | null, withTG8Rev25Conformity?: boolean | null, certificateValidityStatusName: string, certificateTypeId: string, hasActiveErrorReport: boolean }> | null } | null, unitBridgeUsers?: { __typename?: 'UnitBridgeUserCollectionSegment', items?: Array<{ __typename?: 'UnitBridgeUser', id: string, unitId: string }> | null } | null };


        export type ExecGetUnitsForUnitsOverviewOptions = Omit<
        Apollo.QueryOptions<
        GetUnitsForUnitsOverviewQueryVariables, GetUnitsForUnitsOverviewQuery
        >,
        "query"
      >;
    export const execGetUnitsForUnitsOverviewQuery = (options: ExecGetUnitsForUnitsOverviewOptions) =>
    client.query< GetUnitsForUnitsOverviewQuery, GetUnitsForUnitsOverviewQueryVariables>({
      ...options,
      query: GetUnitsForUnitsOverviewDocument,
    });
    

export const GetUnitsForUnitsOverviewDocument = gql`
    query getUnitsForUnitsOverview($skip: Int, $take: Int, $where: UnitOverviewFilterInput, $order: [UnitOverviewSortInput!], $isLoggedIn: Boolean = false) {
  unitOverview(skip: $skip, take: $take, where: $where, order: $order) {
    totalCount
    items {
      id
      isImported
      tenantId
      modelName
      manufacturerId
      ratedCurrent
      unitCode
      maxActivePower
      hasActivePowerRange
      hasApparentPowerRange
      certificateId
      maxActivePower
      maxActivePowerRange
      maxApparentPowerRange
      minActivePowerRange
      minApparentPowerRange
      ratedVoltage
      unitTypeId
      certificateNormIssueDateDescriptions
      is4105
      unitTypeId
      category
      certificateIsImported
      isVerified
      certificateIssueDate
      certificateHolderId
      primaryEnergySource
      certificateNumber
      validityStartDate
      validityEndDate
      inEditByTenantId
      withTG8Rev25Conformity
      certificateValidityStatusName
      certificateTypeId
      hasActiveErrorReport
      __typename
    }
    __typename
  }
  unitBridgeUsers @include(if: $isLoggedIn) {
    items {
      id
      unitId
    }
  }
}
    `;

/**
 * __useGetUnitsForUnitsOverviewQuery__
 *
 * To run a query within a React component, call `useGetUnitsForUnitsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsForUnitsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsForUnitsOverviewQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      isLoggedIn: // value for 'isLoggedIn'
 *   },
 * });
 */
export function useGetUnitsForUnitsOverviewQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitsForUnitsOverviewQuery, GetUnitsForUnitsOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsForUnitsOverviewQuery, GetUnitsForUnitsOverviewQueryVariables>(GetUnitsForUnitsOverviewDocument, options);
      }
export function useGetUnitsForUnitsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsForUnitsOverviewQuery, GetUnitsForUnitsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsForUnitsOverviewQuery, GetUnitsForUnitsOverviewQueryVariables>(GetUnitsForUnitsOverviewDocument, options);
        }
export type GetUnitsForUnitsOverviewQueryHookResult = ReturnType<typeof useGetUnitsForUnitsOverviewQuery>;
export type GetUnitsForUnitsOverviewLazyQueryHookResult = ReturnType<typeof useGetUnitsForUnitsOverviewLazyQuery>;
export type GetUnitsForUnitsOverviewQueryResult = Apollo.QueryResult<GetUnitsForUnitsOverviewQuery, GetUnitsForUnitsOverviewQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;