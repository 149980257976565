import { getStringsSave } from "translations"

function beautifyString(input: string): string {
  // Remove any leading numbers
  const noNumbers = input.replace(/d+$/, "")

  // Split the string into words
  const words = noNumbers.split("_")

  // Capitalize the first letter of each word and make the rest lowercase
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )

  // Join the words back together with spaces
  const output = capitalizedWords.join(" ")

  return output
}

const getEnumTranslation = (value: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return (
    getStringsSave(`enum.${value}`) ??
    beautifyString(value.split(".").pop() ?? "")
  )
}

const enums = {
  applyPolicy: {
    BEFORE_RESOLVER: getEnumTranslation("applyPolicy.BEFORE_RESOLVER"),
    AFTER_RESOLVER: getEnumTranslation("applyPolicy.AFTER_RESOLVER"),
  },
  approvalStatus: {
    PENDING: getEnumTranslation("approvalStatus.PENDING"),
    DENIED: getEnumTranslation("approvalStatus.DENIED"),
    APPROVED: getEnumTranslation("approvalStatus.APPROVED"),
    PENDING_INVITATION: getEnumTranslation("approvalStatus.PENDING_INVITATION"),
  },
  issuer: {
    FACEBOOK: getEnumTranslation("issuer.FACEBOOK"),
    GOOGLE: getEnumTranslation("issuer.GOOGLE"),
    MICROSOFT: getEnumTranslation("issuer.MICROSOFT"),
    LOCAL: getEnumTranslation("issuer.LOCAL"),
  },
  sortEnumType: {
    ASC: getEnumTranslation("sortEnumType.ASC"),
    DESC: getEnumTranslation("sortEnumType.DESC"),
  },
  tenantRole: {
    MANUFACTURER: getEnumTranslation("tenantRole.MANUFACTURER"),
    CERTIFICATION_AUTHORITY: getEnumTranslation(
      "tenantRole.CERTIFICATION_AUTHORITY"
    ),
    NETWORK_OPERATOR: getEnumTranslation("tenantRole.NETWORK_OPERATOR"),
    PLATFORM_OPERATOR: getEnumTranslation("tenantRole.PLATFORM_OPERATOR"),
    PLANNER: getEnumTranslation("tenantRole.PLANNER"),
  },
  notificationFrequency: {
    IMMEDIATE: getEnumTranslation("notificationFrequency.IMMEDIATE"),
    WEEKLY: getEnumTranslation("notificationFrequency.WEEKLY"),
    MONTHLY: getEnumTranslation("notificationFrequency.MONTHLY"),
  },
  notificationStatus: {
    OPEN: getEnumTranslation("notificationStatus.OPEN"),
    CLOSED: getEnumTranslation("notificationStatus.CLOSED"),
  },
  notificationSubscriberType: {
    CERTIFICATE_HOLDER: getEnumTranslation(
      "notificationSubscriberType.CERTIFICATE_HOLDER"
    ),
    CERTIFICATATION_BODY: getEnumTranslation(
      "notificationSubscriberType.CERTIFICATATION_BODY"
    ),
    NETWORK_OPERATOR: getEnumTranslation(
      "notificationSubscriberType.NETWORK_OPERATOR"
    ),
    PLANNER_OPERATOR: getEnumTranslation(
      "notificationSubscriberType.PLANNER_OPERATOR"
    ),
  },
  notificationType: {
    READ_REQUEST: getEnumTranslation("notificationType.READ_REQUEST"),
    UPLOAD: getEnumTranslation("notificationType.UPLOAD"),
    ISSUE: getEnumTranslation("notificationType.ISSUE"),
    CHANGE: getEnumTranslation("notificationType.CHANGE"),
    SUMMARY: getEnumTranslation("notificationType.SUMMARY"),
    EXPIRY: getEnumTranslation("notificationType.EXPIRY"),
  },
  accessStatus: {
    REQUESTED: getEnumTranslation("accessStatus.REQUESTED"),
    APPROVED: getEnumTranslation("accessStatus.APPROVED"),
    REJECTED: getEnumTranslation("accessStatus.REJECTED"),
  },
  category: {
    INVERTER: getEnumTranslation("category.INVERTER"),
    STORAGE_INVERTER: getEnumTranslation("category.STORAGE_INVERTER"),
    ASYNCHRONOUS_GENERATOR: getEnumTranslation(
      "category.ASYNCHRONOUS_GENERATOR"
    ),
    SYNCHRONOUS_GENERATOR: getEnumTranslation("category.SYNCHRONOUS_GENERATOR"),
    FUEL_CELL: getEnumTranslation("category.FUEL_CELL"),
    UNDERVOLTAGE_PROTECTION: getEnumTranslation(
      "category.UNDERVOLTAGE_PROTECTION"
    ),
    PAV_E_MONITORING: getEnumTranslation("category.PAV_E_MONITORING"),
    VOLTAGE_REGULATOR: getEnumTranslation("category.VOLTAGE_REGULATOR"),
    PROTECTIVE_DEVICE_INTERMEDIATE: getEnumTranslation(
      "category.PROTECTIVE_DEVICE_INTERMEDIATE"
    ),
    EZA_CONTROLLER: getEnumTranslation("category.EZA_CONTROLLER"),
    OTHER: getEnumTranslation("category.OTHER"),
  },
  historyTableType: {
    CERTIFICATE_BRIDGE_CERTIFICATE_NORM_ISSUE_DATE: getEnumTranslation(
      "historyTableType.CERTIFICATE_BRIDGE_CERTIFICATE_NORM_ISSUE_DATE"
    ),
    CERTIFICATE_BRIDGE_UNIT: getEnumTranslation(
      "historyTableType.CERTIFICATE_BRIDGE_UNIT"
    ),
    CERTIFICATE_BRIDGE_SOFTWARE_VERSION: getEnumTranslation(
      "historyTableType.CERTIFICATE_BRIDGE_SOFTWARE_VERSION"
    ),
    CERTIFICATE_FILE: getEnumTranslation("historyTableType.CERTIFICATE_FILE"),
    CERTIFICATE: getEnumTranslation("historyTableType.CERTIFICATE"),
    CERTIFICATE_NORM_BRIDGE: getEnumTranslation(
      "historyTableType.CERTIFICATE_NORM_BRIDGE"
    ),
    UNIT: getEnumTranslation("historyTableType.UNIT"),
  },
  netFeedIn: {
    SINGLE_PHASE: getEnumTranslation("netFeedIn.SINGLE_PHASE"),
    TWO_PHASE: getEnumTranslation("netFeedIn.TWO_PHASE"),
    THREE_PHASE: getEnumTranslation("netFeedIn.THREE_PHASE"),
    UNKNOWN: getEnumTranslation("netFeedIn.UNKNOWN"),
  },
  primaryEnergySource: {
    WIND: getEnumTranslation("primaryEnergySource.WIND"),
    PV: getEnumTranslation("primaryEnergySource.PV"),
    PV_WITH_STORAGE: getEnumTranslation("primaryEnergySource.PV_WITH_STORAGE"),
    OTHER_FUELS: getEnumTranslation("primaryEnergySource.OTHER_FUELS"),
    STORAGE: getEnumTranslation("primaryEnergySource.STORAGE"),
    WATER: getEnumTranslation("primaryEnergySource.WATER"),
    BIOGAS: getEnumTranslation("primaryEnergySource.BIOGAS"),
    OTHER: getEnumTranslation("primaryEnergySource.OTHER"),
    NOT_APPLICABLE: getEnumTranslation("primaryEnergySource.NOT_APPLICABLE"),
  },
  softwareType: {
    MATLAB: getEnumTranslation("softwareType.MATLAB"),
    SIMULINK: getEnumTranslation("softwareType.SIMULINK"),
    D_IG_SILENT: getEnumTranslation("softwareType.D_IG_SILENT"),
    UNKNOWN: getEnumTranslation("softwareType.UNKNOWN"),
  },
}
export default enums
export type Enums = typeof enums
