import {
  MappingValueGetter,
  UnitFieldMapping,
  UnitFieldMappingGetter,
  UploadCertificateUnit,
} from "pages/UploadCertificate/types";
import { getManufacturer } from "./excelToCertificateFieldMappings";

const formatNumber: MappingValueGetter<UploadCertificateUnit, keyof UploadCertificateUnit> = ({ value }) => {
  if (value === "") return undefined;
  const number = Number(value.replace(",", "."));
  if (isNaN(number)) return undefined;
  return number;
};

export const unitTypeNames = [
  {
    id: "c17efa44-c35d-502e-94a6-161c4f183cbb",
    unitTypeName: "Brennstoffzelle",
  },
  {
    id: "da91f6d4-dfc7-5551-9d4c-275ab15795c2",
    unitTypeName: "Umrichter",
  },
  {
    id: "7a7b683c-e647-5589-89fa-2abe921a8ca8",
    unitTypeName: "Synchrongenerator",
  },
  {
    id: "e683285d-8040-5ab4-9d40-40aebc85c06c",
    unitTypeName: "PAV,E-Überwachung",
  },
  {
    id: "3aeabb60-fe26-5c07-a616-698d11ac5252",
    unitTypeName: "EZA-Regler",
  },
  {
    id: "daad643c-18c2-501b-be70-75ff4ff3d730",
    unitTypeName: "Andere",
  },
  {
    id: "6e2a4fb0-14ae-5608-a863-ab0a898f4c0b",
    unitTypeName: "Asynchrongenerator",
  },
  {
    id: "02adcb6e-5df0-5158-998e-bddf0f0e3de1",
    unitTypeName: "NA-Schutz",
  },
  {
    id: "57412cf3-25d0-5014-9702-d9a263748824",
    unitTypeName: "Umrichter des Speichers",
  },
  {
    id: "04f970bf-43b9-5d47-bf13-dcac8db0256c",
    unitTypeName: "Schutzgerät (Zwischengelagert)",
  },
  {
    id: "8a2e5b88-dbb6-5c38-9274-f3eb2881c246",
    unitTypeName: "Spannungsregler",
  },
];

const NetFeedIns = {
  "1-phasig": "SINGLE_PHASE",
  "1-phase": "SINGLE_PHASE",
  "3-phasig": "THREE_PHASE",
  "3-phase": "THREE_PHASE",
  "2-phasig": "TWO_PHASE",
  "2-phase": "TWO_PHASE",
  UNKNOWN: "UNKNOWN",
};

const getUnitTypeNames: UnitFieldMappingGetter = ({ value }) => {
  const unitTypeName = unitTypeNames.find((unitTypeName) => unitTypeName.unitTypeName === value);
  if (!unitTypeName) return undefined;
  return unitTypeName.id;
};

const getNetFeetIn: UnitFieldMappingGetter = ({ value }) => {
  const netFeedIn = NetFeedIns[value as keyof typeof NetFeedIns];
  // if (!netFeedIn) return "UNKNOWN";
  return netFeedIn;
};

export const excelToUnitFieldMappings: UnitFieldMapping[] = [
  { key: "1.02", field: "unitTypeId", getter: getUnitTypeNames },
  { key: "1.03", field: "manufacturerId", getter: getManufacturer },
  { key: "1.04", field: "modelName" },
  { key: "1.04", field: "modelNumber" },
  { key: "1.19", field: "netFeedIn", getter: getNetFeetIn },
  { key: "1.20", field: "maxActivePower", getter: formatNumber, maxDecPlaces: 1 },
  { key: "1.21", field: "maxActivePower09", getter: formatNumber, maxDecPlaces: 1 },
  { key: "1.22", field: "maxApparentPower", getter: formatNumber, maxDecPlaces: 1 },
  { key: "1.23", field: "ratedActivePower", getter: formatNumber, maxDecPlaces: 1 },
  { key: "1.24", field: "ratedApparentPower", getter: formatNumber, maxDecPlaces: 1 },
  { key: "1.25", field: "ratedVoltage", getter: formatNumber, maxDecPlaces: 1 },
  { key: "1.26", field: "ratedCurrent", getter: formatNumber, maxDecPlaces: 1 },
  { key: "1.27", field: "shortCircuitPeakCurrent", getter: formatNumber, maxDecPlaces: 1 },
  {
    key: "1.28",
    field: "initialShortCircuitAlternatingCurrent",
    getter: formatNumber,
    maxDecPlaces: 1,
  },
  {
    key: "1.29",
    field: "operation1MaxSwitchN10",
    getter: formatNumber,
    maxDecPlaces: 0,
  },
  {
    key: "1.30",
    field: "operation1MaxNumberN120",
    getter: formatNumber,
    maxDecPlaces: 0,
  },
  {
    key: "1.31",
    field: "operation1VoltageVariationFactor30",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.32",
    field: "operation1VoltageVariationFactor50",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.33",
    field: "operation1VoltageVariationFactor70",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.34",
    field: "operation1VoltageVariationFactor85",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.35",
    field: "operation1FlickerStepFactor30",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.36",
    field: "operation1FlickerStepFactor50",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.37",
    field: "operation1FlickerStepFactor70",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.38",
    field: "operation1FlickerStepFactor85",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.39",
    field: "operation2MaxSwitchN10",
    getter: formatNumber,
    maxDecPlaces: 0,
  },
  {
    key: "1.40",
    field: "operation2MaxNumberN120",
    getter: formatNumber,
    maxDecPlaces: 0,
  },
  {
    key: "1.41",
    field: "operation2VoltageVariationFactor30",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.42",
    field: "operation2VoltageVariationFactor50",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.43",
    field: "operation2VoltageVariationFactor70",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.44",
    field: "operation2VoltageVariationFactor85",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.45",
    field: "operation2FlickerStepFactor30",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.46",
    field: "operation2FlickerStepFactor50",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.47",
    field: "operation2FlickerStepFactor70",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.48",
    field: "operation2FlickerStepFactor85",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.49",
    field: "operation3MaxSwitchN10",
    getter: formatNumber,
    maxDecPlaces: 0,
  },
  {
    key: "1.50",
    field: "operation3MaxNumberN120",
    getter: formatNumber,
    maxDecPlaces: 0,
  },
  {
    key: "1.51",
    field: "operation3VoltageVariationFactor30",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.52",
    field: "operation3VoltageVariationFactor50",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.53",
    field: "operation3VoltageVariationFactor70",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.54",
    field: "operation3VoltageVariationFactor85",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.55",
    field: "operation3FlickerStepFactor30",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.56",
    field: "operation3FlickerStepFactor50",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.57",
    field: "operation3FlickerStepFactor70",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.58",
    field: "operation3FlickerStepFactor85",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.59",
    field: "fastVoltageChangeTurnOnWithoutSpecifications",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.60",
    field: "fastVoltageChangeWorstCaseDuringGeneratorStageSwitching",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.61",
    field: "fastVoltageChangeTurnOnAtRatedConditions",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.62",
    field: "fastVoltageChangeTurnOffAtRatedPower",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.63",
    field: "operationAllWorstCase",
    getter: formatNumber,
    maxDecPlaces: 3,
  },
  {
    key: "1.64",
    field: "systemFlickerCoefficientCY30",
    getter: formatNumber,
    maxDecPlaces: 1,
  },
  {
    key: "1.65",
    field: "systemFlickerCoefficientCY50",
    getter: formatNumber,
    maxDecPlaces: 1,
  },
  {
    key: "1.66",
    field: "systemFlickerCoefficientCY70",
    getter: formatNumber,
    maxDecPlaces: 1,
  },
  {
    key: "1.67",
    field: "systemFlickerCoefficientCY85",
    getter: formatNumber,
    maxDecPlaces: 1,
  },
  // {
  //   key: "1.36",
  //   field: "softwareTypeAndVersion",
  // },
];

// console.log(
//   excelToUnitFieldMappings.reduce((s, v, index) => {
//     if (index < 4) return s;
//     return `${s}${v.field}:makeGenericUnitFieldRule("${v.field}"), \n`;
//   }, "")
// );

// key	x	x			Anlagenkennung					Text	field
// 1.19	x	x			Netzeinspeisung					Auswahl	Unit.NetFeedIn (Enum)
// 1.20	x	x			Maximale Wirkleistung			PEmax	kW	"Dezimalzahl
// (1 Nachkommastelle)"	Unit.MaxActivePower
// 1.21	x	x			Maximale Scheinleistung			SEmax	kVA	"Dezimalzahl
// (1 Nachkommastelle)"	Unit.MaxApparentPower
// 1.22	x	x			Bemessungswirkleistung			PrE	kW	"Dezimalzahl
// (1 Nachkommastelle)"	Unit.RatedActivePower
// 1.23	x	x			Bemessungsscheinleistung			SrE	kVA	"Dezimalzahl
// (1 Nachkommastelle)"	Unit.RatedApparentPower
// 1.24	x	x			Bemessungsspannung			Ur	V	Ganzzahl	Unit.RatedVoltage
// 1.25	x	x			Bemessungsstrom			 Ir	A	"Dezimalzahl
// (1 Nachkommastelle)"	Unit.RatedCurrent
// 1.26	x	x			Stoßkurzschlussstrom			ip	A	"Dezimalzahl
// (1 Nachkommastelle)"	Unit.ShortCircuitPeakCurrent
// 1.27	x	x			Anfangs-Kurzschlusswechselstrom			I″k	A	"Dezimalzahl
// (1 Nachkommastelle)"	Unit.InitialShortCircuitAlternatingCurrent
// 1.28		x			Schnelle Spannungsänderung: Einschalten ohne Vorgabe (zum Primärenergieträger)			ki		"Dezimalzahl
// (3 Nachkommastellen)"	Unit.FastVoltageChangeTurnOnWithoutSpecifications
// 1.29		x			Schnelle Spannungsänderung: Ungünstigster Fall beim Umschalten der Generatorstufen			ki		"Dezimalzahl
// (3 Nachkommastellen)"	Unit.FastVoltageChangeWorstCaseDuringGeneratorStageSwitching
// 1.30		x			Schnelle Spannungsänderung: Einschalten bei Nennbedingungen (des Primärenergieträgers)			ki		"Dezimalzahl
// (3 Nachkommastellen)"	Unit.FastVoltageChangeTurnOnAtRatedConditions
// 1.31		x			Schnelle Spannungsänderung: Ausschalten bei Bemessungsleistung			ki		"Dezimalzahl
// (3 Nachkommastellen)"	Unit.FastVoltageChangeTurnOffAtRatedPower
// 1.32		x			Flicker, Anlagenflickerbeiwert cY, 30°			cY		"Dezimalzahl
// (1 Nachkommastelle)"	Unit.SystemFlickerCoefficientCY30
// 1.33		x			Flicker, Anlagenflickerbeiwert cY, 50°			cY		"Dezimalzahl
// (1 Nachkommastelle)"	Unit.SystemFlickerCoefficientCY50
// 1.34		x			Flicker, Anlagenflickerbeiwert cY, 70°			cY		"Dezimalzahl
// (1 Nachkommastelle)"	Unit.SystemFlickerCoefficientCY70
// 1.35		x			Flicker, Anlagenflickerbeiwert cY, 85°			cY		"Dezimalzahl
// (1 Nachkommastelle)"	Unit.SystemFlickerCoefficientCY85
// 1.36		x			Softwareumgebung und Versionsnummer in der das Modell validiert wurde					Text	SoftwareType + Version
