export default {
  columnOptions: "Spaltenoptionen",
  searchTableDefault: "Tabelle durchsuchen",
  searchTasks: "Aufgaben durchsuchen",
  editUser: "Benutzer bearbeiten",
  retryInviteUser: "Benutzer erneut einladen",
  blockUser: "Benutzer blockieren",
  unblockUser: "Benutzer entsperren",
  editTenant: "Organisation bearbeiten",
  sort: {
    labelAsc: "Aufwärts",
    labelDesc: "Abwärts",
  },
  pagination: { rowsPerPage: "Einträge pro Seite:" },
};
