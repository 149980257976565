import {
  makeBridgeState,
  MakeBridgeStateParams,
} from "@ml-pa/react-apollo-form-tool/GenericBrigdeUtils/gerenericBridgeState";
import { GetCertificateToModifyByIdQuery } from "graphqlBase/certificates/__generated__/getCertificateToModifyById";
import { atom, selector, useRecoilCallback } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { dummyIdPrefix, formDefaultKeys } from "../config";
import {
  CerticateUploadProcessType,
  FormValueGetter,
  RowJson,
  UploadCertificate,
  UploadCertificateFile,
} from "../types";
import { uploadCertificateFilesState } from "./uploadCertificateFileState";
import { activeUploadCertificateUnitIndexAtom, uploadCertificateUnitListAtom } from "./uploadCertificateUnitState";

import wait from "utils/wait";
import { getCertificateCreateFieldSelector } from "raft/CertificateForm";
import { userInfoAtom } from "lib/userInfo/state";
export const uploadCertificateStateAtom = atom<UploadCertificate | undefined>({
  key: "uploadCertificateStateAtom",
  default: undefined,
});

export const isUploadCertificateLoadingAtom = atom<boolean>({
  key: "isUploadCertificateLoadingAtom",
  default: false,
});
export const importedExcelOnExistingCertificate = atom<boolean>({
  key: "importedExcelOnExistingCertificate",
  default: false,
});

export const certicateUploadProcessTypeAtom = atom<CerticateUploadProcessType | undefined>({
  key: "certicateUploadProcessTypeAtom",
  default: undefined,
});
export const certificateToReplaceIdAtom = atom<string | undefined>({
  key: "certificateToReplaceIdAtom",
  default: undefined,
});

export const certificateToEditIdAtom = atom<string | undefined>({
  key: "certificateToEditIdAtom",
  default: undefined,
  // default: "9283c6eb-0fb6-4631-9d21-3286009500d4",
});

export const certificOnRemoteAtom = atom<GetCertificateToModifyByIdQuery["certificate"] | undefined>({
  key: "certificOnRemoteAtom",
  default: undefined,
});

export const isImportGoingToOverwriteSelector = selector<boolean>({
  key: "isImportGoingToOverwriteSelector",
  get: ({ get }) => {
    const uploadCertificateState = get(uploadCertificateStateAtom);
    const uploadCertificateUnitsState = get(uploadCertificateUnitListAtom);
    const keys = Object.keys(uploadCertificateUnitsState[0] || {}).filter((key) => !formDefaultKeys.includes(key));
    if (
      Object.keys(uploadCertificateState || {}).length > 0 ||
      uploadCertificateUnitsState.length > 1 ||
      keys.length > 0
    ) {
      return true;
    }
    return false;
  },
});

const makeBridgeAtomState = (ids: string[]) => {
  const v = ids.reduce((acc, id) => {
    acc[id] = { outerId: id, isDeleted: false };
    return acc;
  }, {} as { [key: string]: { outerId: string; isDeleted: boolean } });
  return v;
};

export const certificateBridgeCertificateNormIssueDatesState = makeBridgeState({
  bridgeParams: { bridgeEntityName: "certificateBridgeCertificateNormIssueDates" },
} as MakeBridgeStateParams);

const certificateBridgeSoftwareVersionState = makeBridgeState({
  bridgeParams: { bridgeEntityName: "certificateBridgeSoftwareVersions" },
} as MakeBridgeStateParams);

export const useImportValuesToCertificateUploadForm = (getFormValues: FormValueGetter, isEdit = false) => {
  return useRecoilCallback(
    ({ set, reset, snapshot }) =>
      async (columns: RowJson[][]) => {
        const userInfo = await snapshot.getPromise(userInfoAtom);
        const role = userInfo?.role;
        if (!role) return;

        set(isUploadCertificateLoadingAtom, true);
        const formValues = await getFormValues({ columns });
        if (!formValues) {
          set(isUploadCertificateLoadingAtom, false);
          return;
        }
        const {
          certificateUpload,
          certificateUploadUnits,
          certificateBridgeCertificateNormIssueDates,
          certificateBridgeSoftwareVersions,
        } = formValues;

        const certificateUploadForRole = {
          ...certificateUpload,
          certificateHolderId: role === "MANUFACTURER" ? userInfo.tenantId : certificateUpload.certificateHolderId,
          certificateAuthorityId:
            role === "CERTIFICATION_AUTHORITY" ? userInfo.tenantId : certificateUpload.certificateAuthorityId,
        };

        set(activeUploadCertificateUnitIndexAtom, -1);
        reset(uploadCertificateUnitListAtom);
        if (!isEdit) {
          reset(uploadCertificateStateAtom);
          reset(certificateBridgeCertificateNormIssueDatesState.state);
          reset(certificateBridgeSoftwareVersionState.state);

          set(uploadCertificateStateAtom, certificateUploadForRole);

          await wait(500);
          set(
            certificateBridgeCertificateNormIssueDatesState.state,
            makeBridgeAtomState(certificateBridgeCertificateNormIssueDates)
          );
          await wait(500);
          set(certificateBridgeSoftwareVersionState.state, makeBridgeAtomState(certificateBridgeSoftwareVersions));
        } else {
          set(importedExcelOnExistingCertificate, true);
        }
        set(uploadCertificateUnitListAtom, certificateUploadUnits);
        await wait(50);
        set(activeUploadCertificateUnitIndexAtom, 0);
        set(isUploadCertificateLoadingAtom, false);
      },
    []
  );
};

export const useSetValuesForCertificateToModify = (isEdit?: boolean) => {
  const __modifiedByUserAtom = getCertificateCreateFieldSelector({})("__modifiedByUser");
  return useRecoilCallback(
    ({ set, reset }) =>
      async (toModify: GetCertificateToModifyByIdQuery) => {
        if (!toModify.certificate) {
          set(isUploadCertificateLoadingAtom, false);
          return;
        }
        set(certificOnRemoteAtom, toModify.certificate);
        const {
          certificateBridgeCertificateNormIssueDates,
          certificateBridgeSoftwareVersions,
          certificateBridgeUnits,
          revision,
          ...certificateUploadPre
        } = toModify.certificate;
        const nextRevision = (revision ?? 0) + 1;
        const certificateUpload: UploadCertificate = {
          ...certificateUploadPre,
          id: undefined,
          __certificateToReplaceId: isEdit ? undefined : toModify.certificate.id,
          revision: isEdit ? revision : nextRevision,
          isVerified: false,
          __modifiedByUser: 0,
        };

        const certificateUploadUnits =
          certificateBridgeUnits.map(({ unit }, index) => {
            const { __typename, unitType, ...rest } = unit;
            return {
              ...rest,
              id: isEdit ? rest.id : `${dummyIdPrefix}${uuidv4()}`,
              __unitToReplaceId: unit.id,
              __isLinked: undefined,
              revision: nextRevision,
            };
          }) ?? [];

        const certificateBridgeSoftwareVersionsValues = certificateBridgeSoftwareVersions.map(
          ({ softwareVersionId }) => softwareVersionId
        );

        const certificateBridgeCertificateNormIssueDatesValues = certificateBridgeCertificateNormIssueDates.map(
          ({ certificateNormIssueDate }) => certificateNormIssueDate.id
        );

        set(activeUploadCertificateUnitIndexAtom, -1);
        reset(uploadCertificateUnitListAtom);
        reset(uploadCertificateStateAtom);
        reset(certificateBridgeCertificateNormIssueDatesState.state);
        reset(certificateBridgeSoftwareVersionState.state);

        set(uploadCertificateStateAtom, certificateUpload);
        set(uploadCertificateUnitListAtom, certificateUploadUnits);

        await wait(50);
        set(activeUploadCertificateUnitIndexAtom, 0);

        await wait(500);
        set(
          certificateBridgeCertificateNormIssueDatesState.state,
          makeBridgeAtomState(certificateBridgeCertificateNormIssueDatesValues)
        );
        await wait(500);
        set(certificateBridgeSoftwareVersionState.state, makeBridgeAtomState(certificateBridgeSoftwareVersionsValues));
        if (isEdit) {
          const exitistingFiles = (toModify.certificate.certificateFiles ?? []).reduce<UploadCertificateFile[]>(
            (files, file) => {
              if (!file) return files;
              return files.concat({
                ...file,
                base64FileString: "",
                certificateId: toModify.certificate?.id ?? "",
                size: 0,
              });
              return files;
            },
            []
          );
          await wait(300);
          set(uploadCertificateFilesState, exitistingFiles);
          await wait(300);
          // setTimeout(() => {
          //   console.log("set modified by user");
          set(__modifiedByUserAtom, 1);
          // }, 300);
        }

        set(isUploadCertificateLoadingAtom, false);
      },
    []
  );
};

export const useResetForm = () => {
  return useRecoilCallback(({ reset }) => () => {
    reset(uploadCertificateUnitListAtom);
    reset(uploadCertificateStateAtom);
    reset(certificateBridgeCertificateNormIssueDatesState.state);
    reset(certificateBridgeSoftwareVersionState.state);
    reset(activeUploadCertificateUnitIndexAtom);
    reset(uploadCertificateFilesState);
    reset(uploadCertificateUnitListAtom);
    reset(certicateUploadProcessTypeAtom);
    reset(isUploadCertificateLoadingAtom);
  });
};
