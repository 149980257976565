import { FormHelperText, InputBase, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import {
  getTranslatedApprovalStatusString,
  getTranslatedNetFeedInString,
  getTranslatedPrimaryEnergySourceString,
  getTranslatedTenantRoleString,
} from "lib/helper/getTranslatedRowDataStrings";
import raftEnums from "raft/internal/enums";
import * as React from "react";
import ordinaryColors from "themes/ordinaryColors";
import Props from "./index.d";
import { LabelArray, getSortedOptions } from "lib/helper/getSortedOptions";
import { useRecoilValue } from "recoil";
import { userIsPlatformOperatorAtom } from "pages/UserProfile/recoil";

const StyledInput = styled(InputBase)(({ error }) => ({
  maxHeight: "43px",
  display: "flex",
  padding: "8px 12px",
  alignContent: "center",
  rowGap: "100px",
  flex: "1 0 0",
  flexWrap: "wrap",
  borderRadius: "0px",
  border: "1px solid ",
  borderColor: error ? ordinaryColors.error : ordinaryColors.border,
  backgroundColor: error ? "rgba(211, 47, 47, 0.02)" : "transparent",
  marginBottom: "0px",
}));

const EnumSelector: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, setNull },
    disabled,
    testId,
    className,
    errorManager,
    isUnit,
  } = props;

  const userIsPlatformOperator = useRecoilValue(userIsPlatformOperatorAtom);

  const change = (e: SelectChangeEvent) => {
    const value = e.target.value;

    if (value === null) {
      if (setNull) {
        setNull();
      } else {
        setstate("");
      }
    } else {
      if (typeof value === "string") setstate(value);
    }
  };

  const enums = raftEnums[props.enum];
  const optionsPre = Object.entries(enums).map((entry) => ({ value: entry[0], label: entry[1] }));
  const options =
    optionsPre && props.enum === "primaryEnergySource"
      ? (getSortedOptions({ labelArray: optionsPre, sortFn: getTranslatedPrimaryEnergySourceString }) as LabelArray)
      : optionsPre && props.enum === "tenantRole"
      ? (getSortedOptions({
          labelArray: props.isUserManagement
            ? optionsPre.filter((option) => option.value !== "TENANT_ADMIN")
            : optionsPre,
          sortFn: getTranslatedTenantRoleString,
        }) as LabelArray)
      : optionsPre;

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  return (
    <>
      <Select
        value={state ?? ""}
        onChange={change}
        input={<StyledInput error={errorManagerRes.error} />}
        disabled={props.enum === "tenantRole" && !userIsPlatformOperator ? true : disabled}
        className={className}
        data-testid={testId}
        {...errorManagerRes}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {props.enum === "primaryEnergySource" && getTranslatedPrimaryEnergySourceString(option.value)}
            {props.enum === "netFeedIn" && getTranslatedNetFeedInString(option.value)}
            {props.enum === "tenantRole" && getTranslatedTenantRoleString(option.value)}
            {props.enum === "approvalStatus" && getTranslatedApprovalStatusString(option.value)}
          </MenuItem>
        ))}
      </Select>
      {errorManagerRes.error && !isUnit && (
        <FormHelperText sx={{ color: ordinaryColors.error, marginLeft: "0px", fontSize: "12px" }}>
          {errorManagerRes.helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default EnumSelector;
