export default {
  columnOptions: "Column Options",
  searchTableDefault: "Search table",
  searchTasks: "Search tasks",
  editUser: "Edit User",
  blockUser: "Block User",
  unblockUser: "Unblock User",
  retryInviteUser: "Retry Invite User",
  editTenant: "Edit Tenant",
  sort: {
    labelAsc: "ASC",
    labelDesc: "DESC",
  },
  pagination: { rowsPerPage: "Rows per Page:" },
};
