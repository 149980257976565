import { Maybe } from "graphqlBase/types";
import { UnitInputCreate } from "raft/internal/schemaTypes";
import { UnitCreateAdditionalFields } from "raft/UnitForm";
import translations from "translations";
import { excelToUnitFieldMappings } from "../ExcelImport/mapper/excelToUnitFieldMappings";
import { CertficateModify, UploadCertificateUnit } from "../types";

export const categoriesWithLimitedValidation = [
  "UNDERVOLTAGE_PROTECTION",
  "PAV_E_MONITORING",
  "EZA_CONTROLLER",
  "PROTECTIVE_DEVICE_INTERMEDIATE",
];

export const commonMandatoryFields4105 = ["1.19", "1.23", "1.24", "1.25", "1.26", "1.27", "1.28"];
export const commonMandatoryFields4110plus = ["1.20", "1.21", "1.22", "1.23", "1.24", "1.25", "1.26", "1.27", "1.28"];

const _4105ActivePowerOver30 = ["1.59", "1.60", "1.61", "1.62", "1.63", "1.64", "1.65", "1.66", "1.67"];
const _4110plusActivePowerOver30 = [
  "1.29",
  "1.30",
  "1.31",
  "1.32",
  "1.33",
  "1.34",
  "1.35",
  "1.36",
  "1.37",
  "1.38",
  "1.39",
  "1.40",
  "1.41",
  "1.42",
  "1.43",
  "1.44",
  "1.45",
  "1.46",
  "1.47",
  "1.48",
  "1.49",
  "1.50",
  "1.51",
  "1.52",
  "1.53",
  "1.54",
  "1.55",
  "1.56",
  "1.57",
  "1.58",
  "1.64",
  "1.65",
  "1.66",
  "1.67",
];

export const mandatoryForTG8FieldsKeys = [
  "1.01",
  "1.02",
  "1.03",
  "1.04",
  "1.05",
  "1.07",
  "1.08",
  "1.09",
  "1.10",
  "1.11",
  "1.13",
  "1.14",
  "1.15",
  "1.18",
  "1.23",
  "1.24",
  "1.68",
];

export const noValidationCertificateTypeIds = [
  "a980cca4-ca1b-5954-9d95-d784a5008571",
  "a84b6197-3dcc-52f4-a325-6a44157e27d4",
];

export const isManufacturerDeclaration = (certificateTypeId: CertficateModify["certificateTypeId"]) =>
  certificateTypeId === "a980cca4-ca1b-5954-9d95-d784a5008571";

export const doesNormlistContainNorm =
  ({ __normList }: { __normList?: string }) =>
  (norm: string) => {
    return (__normList ?? "").includes(norm);
  };

export const isNormEqualOrGreater10 = ({ __normList }: { __normList?: string }) => {
  const norms = (__normList ?? "").trim();
  return norms.includes("10") || norms.includes("20") || norms.includes("30");
};

export const isNorm5 = ({ __normList }: { __normList?: string }) => {
  const norms = (__normList ?? "").trim();
  return norms.includes("05");
};

export const conditonalRequiredForNormGreaterEqual10 = ({
  value,
  data,
}: {
  value: Maybe<number | string> | undefined;
  data: { __normList?: string };
}) =>
  required({ value }) && isNormEqualOrGreater10(data)
    ? translations.pages.certificateUpload.errorMessages.requieredIfGreater10
    : undefined;

export const required = ({ value }: { value: Maybe<number | string> | undefined }) =>
  (typeof value === "number" ? !value : !value?.length) ? translations.globals.validationMessages.required : undefined;

export const optionalField = {
  rule: () => undefined,
  makeOptional: true,
};

export const categoriesInvalidOnNorm5 = [
  "VOLTAGE_REGULATOR",
  "PROTECTIVE_DEVICE_INTERMEDIATE",
  "EZA_CONTROLLER",
  "OTHER",
];

export const isInteger = ({ value }: { value: Maybe<number | string> | undefined }) => {
  return /^[0-9]+$/.test(value?.toString() ?? "0") ? undefined : translations.globals.validationMessages.integer;
};

export const isCategoryWithLimitedValidation = (category: string) => {
  const res = categoriesWithLimitedValidation.includes(category);
  return res;
};

export const isFieldRequied = (fieldName: keyof UploadCertificateUnit) => {
  const key = excelToUnitFieldMappings.find((v) => v.field === fieldName)?.key;
  if (!key) return () => undefined;

  return async ({
    value,
    data,
    ...rest
  }: {
    value: Maybe<number | string> | undefined;
    data: UnitInputCreate & UnitCreateAdditionalFields;
  }): Promise<string | undefined> => {
    const withTG8Rev25Conformity = data.__withTG8Rev25Conformity ?? false;
    if (
      withTG8Rev25Conformity || // document is TG8 Rev. 9 conform
      !!value || // value is not empty
      value === 0 || // allow zero input
      !data.__certificateTypeId || // no certificate type selected
      (data.__certificateTypeId &&
        (noValidationCertificateTypeIds.includes(data.__certificateTypeId) || !data.__normList)) || // only for verification document of type certificate
      !data.__category || // no category selected
      isCategoryWithLimitedValidation(data.__category) ||
      !data.__normList // no norm selected
    ) {
      return undefined;
    }
    if (isNorm5(data)) {
      if (commonMandatoryFields4105.includes(key)) {
        return translations.globals.validationMessages.required;
      }
      if (
        (data.maxActivePower ?? 0) >= 30 ||
        (!!data.maxActivePowerRange ? data.maxActivePowerRange >= 30 : (data.minActivePowerRange ?? 0) >= 30)
      ) {
        if (_4105ActivePowerOver30.includes(key)) {
          return translations.globals.validationMessages.required;
        }
        return undefined;
      }
      return undefined;
    }
    if (isNormEqualOrGreater10({ __normList: data.__normList })) {
      if (commonMandatoryFields4110plus.includes(key)) {
        return conditonalRequiredForNormGreaterEqual10({ value, data: { __normList: data.__normList } });
      }
      if (
        (data.maxActivePower ?? 0) >= 30 ||
        (!!data.maxActivePowerRange ? data.maxActivePowerRange >= 30 : (data.minActivePowerRange ?? 0) >= 30)
      ) {
        if (_4110plusActivePowerOver30.includes(key)) {
          return translations.globals.validationMessages.required;
        }
        return undefined;
      }
    }
  };
};

export const checkFractionalDigits = (fieldName: keyof UploadCertificateUnit) => {
  const fieldSpec = excelToUnitFieldMappings.find((v) => v.field === fieldName);
  const key = fieldSpec?.key;
  const maxDecPlaces = fieldSpec?.maxDecPlaces ?? -1;
  if (!key || maxDecPlaces === -1) return () => undefined;
  return async ({
    value,
    data,
  }: {
    value: Maybe<number | string> | undefined;
    data: UnitInputCreate & UnitCreateAdditionalFields;
  }): Promise<string | undefined> => {
    if (typeof value === "number") {
      const valueStr = value.toString();
      const decimalIndex = valueStr.indexOf(".");
      if (decimalIndex === -1) return undefined;
      const fractionalDigits = valueStr.length - decimalIndex - 1;
      if (fractionalDigits <= maxDecPlaces) return undefined;
      return translations.pages.certificateUpload.errorMessages.tooManyFractionalDigits.replace(
        `{maxDecPlaces}`,
        `${maxDecPlaces}`
      );
    }
  };
};

export const makeGenericUnitFieldRule = (fieldName: keyof UploadCertificateUnit, rules: (typeof isInteger)[] = []) => {
  return [
    {
      rule: isFieldRequied(fieldName),
      makeOptional: true,
    },
    {
      rule: rules.length ? () => undefined : checkFractionalDigits(fieldName),
    },

    ...rules,
  ];
};
