export default {
  animation: {
    description: "Your filters produced no results. Please try again with adjusting or clearing your filters.",
    title: "No matching search results",
  },
  certificateCheck: "Certificate Check",
  certificateHistory: "Certificate History",
  certificateHistoryClose: "Close Certificate History",
  certificateHistoryIssues: {
    "4e7118b6-4883-567a-b4ad-ab245717ff08": "Error reported",
    "9524be5a-6656-548a-a3c1-754b20df564a": "Certificate verified",
    "b3ef1a67-6644-568f-b8d2-898b2d897562": "Imported",
    "b01df1c0-0ea3-5313-bd0c-54931887c428": "Error corrected",
  },
  certificateInfo: "Certificate Info",
  certificateStatus: "Certificate Status",
  certificateStatuses: {
    expired: "Expired",
    expiring: "Expiring",
    replaced: "Withdrawn (replaced by)",
    valid: "Valid",
    withdrawn: "Withdrawn (not replaced)",
  },
  description:
    "The FGW e.V. connects the energy industry and standardizes the technical language of decentralized and renewable energies. In this section, you will find unit- and component- certificates for various voltage levels. Here, you can verify whether these certificates have been reviewed by an accredited certification body and check their current validity status.",
  editParameters: "Edit Parameters",
  editCertificate: "Edit Certificate",
  errorNote: ({ entity, modelName }: { entity: "unit" | "certificate"; modelName: string | undefined }) => {
    let errorNote = `Please describe the issue you noticed within the ${modelName} certificate. Once the issue is resolved by the responsible party, you will be notified by email.`;
    if (entity === "unit")
      errorNote = `Please describe the issue you noticed within the unit ${modelName}. Once the issue is resolved by the responsible party, you will be notified by email.`;
    return errorNote;
  },
  unitEditWarning: {
    info: "You are currently editing the parameters of a unit. Possible changes might be lost. Please leave the edit mode to continue.",
    header: "Plesase leave the edit mode to continue.",
  },
  filterTitles: {
    certificateStatus: "Certificate Status",
    certificateType: "Type Of Verification Document",
    manufacturer: "Manufacturer",
    authority: "Certification Body",
    maxActivePower: "Max Active Power",
    norm: "Norm",
    ownCertificates: "Own Certificates",
    primaryEnergy: "Primary Energy",
    ratedPower: "Active Power",
    ratedVoltage: "Rated Voltage",
    subscribed: "Subscribed",
    verified: "Verified",
    isNotReplaced: "Only Current Revisions",
    zerezId: "ZEREZ ID",
  },
  label: "Units and Certificates",
  locked: "Locked",
  mostRecentCertificate: "Most Recent Certificate",
  notVerified: "Not Verified",
  verify: "Verify",
  referToDetailView: "refer to detail view",
  reportCertificateError: "Report Certificate Error",
  showUnit: "Go to Unit Detail Page",
  since: "since",
  subscribed: "Subscribed",
  subscribeStar: "Subscription",
  subscribe: "Subscribe",
  title: "ZEREZ - Central registry for unit and component certificates",
  unitParameters: {
    netFeed: "Type Info",
    electricalParameters: "Electrical Parameters",
    fastVoltageChanges: "Rapid Voltage Changes",
    operation1: "Switching Operation 1",
    operation2: "Switching Operation 2",
    operation3: "Switching Operation 3",
    flicker: "Flicker",
    generalInfo: "General Info",
    label: "Unit Parameters",
    powerRanking: "Power Ranking",
  },
  uploadedDocuments: "Uploaded Documents",
  units: "Units",
  noUnitsFound: "No units were found for your search entry",
  verified: "Verified",
  verificationPending: "Verification Pending",
  isErrorReportedUnresolved: "Unresolved Error Report",
  inCorrection: "Ongoing Feedback Process",
  linkToDetailPageOfModel: "Link to Detail Page of Unit",
  listOfConnectionValues: "List of Connection Values",
  zerezIDTextforPowerRange: "refer to range slider",
  requestLockedData: {
    sendRequest: "Send Request",
    title: "Request Access",
    selectAll: "Select all",
    selected: "selected",
    accessRequested: "...Access requested",
    accessDenied: "...Access denied",
    noRequestablesSelected: "To request access, please select the type of information you wish to access.",
    requestConnectionValuesText:
      "I want to have access to the complete list of network connection values for all units covered by this certificate.",
    accessApprovedConnectionValuesText:
      "With your current permission you don’t have an access to the data bellow. To be able to see it request access.",
    requestAccesNetworkConnectionValues:
      "Access to some locked documents has already been requested by you or a member of your organization. You’ll receive an email notification once the certificate holder approves or declines the request",
    requestAccesNetworkConnectionValuesPending:
      "Access to some locked documents has already been requested by you or a member of your organization. You’ll receive an email notification once the certificate holder approves or declines the request",
  },
  zerezIDForPowerRange: "ZEREZ ID for Power Range",
  historization: {
    open: "open",
  },
};
