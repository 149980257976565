import { ReactComponent as BMWKLogo } from "components/icons/iconsSvg/BMWK_Logo.svg";
import translations from "translations";
import { FooterDocumentsContent, FooterDocumentsContentType } from "./FooterDocumentsContent";
import { FooterInfoBoxContent, FooterInfoBoxContentProps } from "./FooterInfoBoxContent";
import {
  StyledFooterRoot,
  StyledFooterContainer,
  StyledFooterInfoBoxContainer,
  StyledDivider,
  StyledFooterDocumentsContainer,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import FooterHeader from "./FooterHeader";

const getFoundedByContent = (): FooterInfoBoxContentProps => [
  {
    headerTitle: translations.globals.footer.fundedBy,
    content: [
      {
        icon: (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <BMWKLogo />
          </Box>
        ),
      },
    ],
  },
];

const getDocumentsContent = ({
  handleImprint,
  handleFaq,
}: {
  handleImprint?: React.MouseEventHandler<HTMLAnchorElement>;
  handleFaq?: React.MouseEventHandler<HTMLAnchorElement>;
}): FooterDocumentsContentType[] => {
  return [
    { label: translations.globals.footer.imprint, onClick: handleImprint ?? (() => null), type: "link" },
    { label: translations.globals.footer.faq, onClick: handleFaq ?? (() => null), type: "link" },
    {
      label: translations.globals.footer.privacyPolicy,
      type: "download",
      data: {
        name: translations.globals.footer.privacyPolicy,
        path: "documents/ZEREZ_Datenschutz_Rev0_dt.pdf",
      },
    },
    {
      label: translations.globals.footer.eula,
      type: "download",
      data: { name: translations.globals.footer.eula, path: "documents/250131_Nutzungsbedingungen ZEREZ.pdf" },
    },
    {
      label: translations.globals.footer.contact,
      type: "mailto",
      data: {
        email: "info@zerez.net",
      },
    },
  ];
};

const Footer = () => {
  const navigate = useNavigate();
  const handleFaq = () => navigate("/faq");
  const handleImprint = () => navigate("/imprint");

  return (
    <StyledFooterRoot>
      <StyledFooterContainer>
        <FooterHeader />
        <StyledFooterInfoBoxContainer>
          <FooterDocumentsContent content={getDocumentsContent({ handleFaq, handleImprint })} />
        </StyledFooterInfoBoxContainer>
        <StyledFooterDocumentsContainer>
          <StyledDivider />
          <FooterInfoBoxContent content={getFoundedByContent()} />
        </StyledFooterDocumentsContainer>
      </StyledFooterContainer>
    </StyledFooterRoot>
  );
};

export default Footer;
